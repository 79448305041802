import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';

import { emailSchema, getUserById, createUserDocument, getValidUserDocument } from '@util/firestore/users';
import AuthProvider, { useAuth } from 'context/AuthContext';

import Button from '@ui/Button';
import FormInput from '../forms/controls/FormInput';
import FormLabel from '../forms/controls/FormLabel';
import SocialLoginButtons from '@c/SocialLoginButton';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';

const PasswordlessAuthForm = ({
  continueFn,
  switchToPasswordTab,
  switchToSignupTab,
}: {
  continueFn: (uid: string, firstName: string, lastName: string, isSocial: boolean) => void;
  switchToPasswordTab: () => void;
  switchToSignupTab: () => void;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { sendPasswordlessLink, loginSocial, isLoggingIn } = useAuth();

  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const formSchema = z.object({
    email: emailSchema,
  });

  type Form = z.infer<typeof formSchema>;

  const { register, handleSubmit, formState, watch } = useForm<Form>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const sendAuthLink: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    setAuthError(null);
    try {
      const success = await sendPasswordlessLink(form.email);
      if (success) {
        localStorage.setItem('email', form.email);
        setLinkSent(true);
      } else {
        setAuthError(new FirebaseError('auth/unknown', 'Failed to send login link.'));
      }
    } catch (error) {
      setLinkSent(false);
      if (error instanceof FirebaseError) {
        setAuthError(error);
      } else {
        setAuthError(new FirebaseError('auth/unknown', 'An unknown error occurred'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);

    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(res);
    } else {
      if (res?.user?.uid) {
        const userDoc = await getUserById(res.user.uid);
        if (!userDoc) {
          const docData = getValidUserDocument(
            res.user.uid,
            res.user.email ?? '',
            {
              firstName: res.user.displayName ? res.user.displayName.split(' ')[0] : 'Anonymous',
              lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            }
          );
          await createUserDocument(docData, s);
          queryClient.invalidateQueries(['user']);
        }
        continueFn(res.user.uid, res.user.displayName?.split(' ')[0] ?? '', res.user.displayName?.split(' ')[1] ?? '', true);
      } else {
        setIsSubmitting(false);
        setAuthError(new FirebaseError('auth/unknown', 'Unknown error'));
      }
    }
  };

  useEffect(() => {
    if (form.email && linkSent) {
      setLinkSent(false);
    }
  }, [form.email]);

  return (
    <div className="w-full max-w-[36rem]">
      <h1 className="text-[2.4rem] font-semibold">Welcome to GearFocus</h1>
      <h2 className="mt-[0.4rem] text-tos">
        Enter your email, and we&apos;ll send you a secure login link.
      </h2>

      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        {linkSent && !authError && (
          <div className="absolute -top-10 w-full">
            <div className="m-auto w-fit justify-center rounded-[10px] border bg-green-50 px-[1.6rem] py-[0.8rem] font-medium text-green-800 text-[1.4rem]">
              We&apos;ve sent you a secure login link. Please check your email.
            </div>
          </div>
        )}

        <form className="flex flex-col gap-[2rem]" onSubmit={handleSubmit(sendAuthLink)}>
          <FormLabel value="Email" errorMessage={errors.email?.message}>
            <FormInput
              type="email"
              placeholder="Enter your email"
              error={!!errors.email}
              {...register('email')}
              autoComplete="email"
            />
          </FormLabel>

          <Button text="Send Login Link" type="secondary" loading={isLoggingIn || isSubmitting} buttonType="submit" />
        </form>

        <div className="flex h-[2.4rem] justify-between">
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
          <p>or continue with</p>
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
        </div>

        <SocialLoginButtons handleSocialClick={handleSocialClick} />

        <div className="mt-[2.4rem] flex w-full text-[1.3rem] lg:mt-[3.2rem]">
          <p>
            Prefer to use a password?&nbsp;
            <button onClick={switchToPasswordTab} className="font-semibold text-brand-secondary">
              Log in
            </button>
            &nbsp;or&nbsp;
            <button onClick={switchToSignupTab} className="font-semibold text-brand-secondary">
              Sign up
            </button>
          </p>
        </div>
      </div>

      <p className="mt-[2.4rem] text-tos lg:mt-[3.2rem]">
        By signing in or signing up, you agree to GearFocus&apos;s&nbsp;
        <LinkTargetBlank href="/terms-and-conditions">Terms of Service</LinkTargetBlank>
        &nbsp;and&nbsp;
        <LinkTargetBlank href="/privacy-policy">Privacy Policy</LinkTargetBlank>.
      </p>
    </div>
  );
};

export default PasswordlessAuthForm;