import { NextSeo } from "next-seo";
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';

import { Tab, Transition } from '@headlessui/react';

import EmailPasswordSignupForm from "./EmailPasswordSignupForm";
import ProfileSetupForm from "./ProfileSetupForm";
import EmailPasswordLoginForm from "./EmailPasswordLoginForn";
import PasswordlessAuthForm from "./PasswordlessAuthForm";

const AuthenticationForm = () => {
  const { user, userDoc } = useAuth();
  const router = useRouter();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    firstName: '',
    lastName: '',
    isSocial: false,
  });

  const [isReady, setIsReady] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const run = async () => {
      try {
        setMessage("Checking your account...")

        // Already logged in
        if (user) {
          await router.push('/dashboard/edit');
          return;
        }

        setMessage("")
        setTabIndex(2)
      } catch (err) {
        setMessage("")
        console.error(err);
      } finally {
        setIsReady(true);
      }
    };

    run();
  }, [router.query, user, userDoc]);

  return (
    <>
      <NextSeo
        title="Sign Up for GearFocus - Buy and Sell Used Camera Gear"
        description="Join GearFocus to buy and sell used camera gear easily. Create your account today and access a marketplace designed for photographers and creators."
      />

      <div className="mt-[2.4rem] flex w-full items-center justify-center px-[2.4rem] pb-[3.2rem] sm:p-0 lg:mt-[3rem]
      ">
        <Tab.Group selectedIndex={isReady ? tabIndex : 0} onChange={setTabIndex}>
          <Tab.List className="hidden">
            <Tab />
            <Tab />
            <Tab />
            <Tab />
            <Tab />
          </Tab.List>
          <Tab.Panels>
            {/* Step 0: Spinner */}
            <Tab.Panel>
              <div className="flex min-h-[40vh] w-full items-center justify-center">
                <div className="text-center text-[1.4rem] font-medium">
                  {message}
                  <div className="mt-[1.2rem] animate-spin rounded-full border-4 border-gray-300 border-t-brand-secondary h-10 w-10 mx-auto" />
                </div>
              </div>
            </Tab.Panel>

            {/* Step 1: Passwordless Auth */}
            <Tab.Panel>
              <Transition appear show={isReady}>
                <PasswordlessAuthForm
                  switchToPasswordTab={() => setTabIndex(2)}
                  switchToSignupTab={() => setTabIndex(3)}
                  continueFn={(uid, firstName, lastName, isSocial) => {
                    setUserInfo({ uid, firstName, lastName, isSocial });
                    setTabIndex(4);
                  }}
                />
              </Transition>
            </Tab.Panel>

            {/* Step 2: Password Login */}
            <Tab.Panel>
              <Transition appear show={isReady}>
                <EmailPasswordLoginForm
                  switchToPasswordlessTab={() => setTabIndex(1)}
                  switchToSignupTab={() => setTabIndex(3)}
                />
              </Transition>
            </Tab.Panel>

            {/* Step 3: Signup */}
            <Tab.Panel>
              <Transition appear show={isReady}>
                <EmailPasswordSignupForm
                  switchToPasswordlessTab={() => setTabIndex(1)}
                  switchToPasswordTab={() => setTabIndex(2)}
                  continueFn={(uid, firstName, lastName, isSocial) => {
                    setUserInfo({ uid, firstName, lastName, isSocial });
                    setTabIndex(4);
                  }}
                />
              </Transition>
            </Tab.Panel>

            {/* Step 4: Profile Setup */}
            <Tab.Panel>
              <Transition appear show={isReady && tabIndex===4}>
                <ProfileSetupForm {...userInfo} />
              </Transition>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default AuthenticationForm;