import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import {
  updateUsername,
  updateUserDoc,
  usernameSchema,
} from '@util/firestore/users';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import FormInput from '../forms/controls/FormInput';
import FormLabel from '../forms/controls/FormLabel';

const ProfileSetupForm = ({
  uid,
  firstName,
  lastName
}: {
  uid: string;
  firstName: string;
  lastName: string;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const missingName = !firstName || !lastName;

  const formSchema = z.object({
    username: usernameSchema,
    first: z.string().min(1, 'First name is required'),
    last: z.string().min(1, 'Last name is required'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
  } = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      username: '',
      first: firstName || '',
      last: lastName || '',
    },
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const first = watch('first');
  const username = watch('username');

  useEffect(() => {
    if (first && username !== `${first}'s Gear Shop`) {
      setValue('username', `${first}'s Gear Shop`);
    }
  }, [first, setValue]);

  const errors = formState.errors;

  const submit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    setIsSubmitting(true);

    try {
      await updateUsername(uid, data.username);

      if (missingName) {
        await updateUserDoc(uid, { 
          first: data.first, 
          last: data.last, 
          name: `${data.first} ${data.last}`,
          photo: `https://ui-avatars.com/api/?background=random&length=2&name=${data.first} ${data.last}&size=256`
        });
      }

      await queryClient.invalidateQueries(['user']);
      await router.push('/dashboard/edit?conversion=signup');
    } catch (error) {
      console.error('Profile setup error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full max-w-[36rem] mb-[4rem] lg:mb-[6rem]">
      <h1 className="text-[2.4rem] font-semibold">Complete your Profile</h1>
      <h2 className="mt-[0.4rem] text-[1.3rem]">
        Your username will be displayed publicly to other users.
      </h2>

      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        <form className="flex flex-col gap-[2rem]" onSubmit={handleSubmit(submit)}>
          {missingName && (
            <>
              <FormLabel required value="First Name" errorMessage={errors.first?.message}>
                <FormInput
                  placeholder="Enter your first name"
                  error={!!errors.first}
                  {...register('first')}
                />
              </FormLabel>
              <FormLabel required value="Last Name" errorMessage={errors.last?.message}>
                <FormInput
                  placeholder="Enter your last name"
                  error={!!errors.last}
                  {...register('last')}
                />
              </FormLabel>
            </>
          )}

          <FormLabel required value="Username" errorMessage={errors.username?.message}>
            <FormInput
              placeholder="Your Gear Shop"
              error={!!errors.username}
              autoComplete="off"
              {...register('username')}
            />
          </FormLabel>

          <div className="mt-5 w-full">
            <Button
              text="Complete"
              type="secondary"
              loading={isSubmitting}
              buttonType="submit"
              width="fluid"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileSetupForm;