import AuthenticationForm from './authentication/AuthenticationForm';

const LoginOrSignupPage = () => {
  return (
    <div className="w-full">
      <AuthenticationForm />
    </div>
  );
};

export default LoginOrSignupPage;
