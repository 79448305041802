import { CheckmarkIcon } from '@c/icons'; 

export const ActiveCheckIcon = ({ active }: { active: boolean }) => {
  return (
    <div
      className={`flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full p-[0.3rem] ${
        active ? 'bg-brand-primary' : 'bg-brand-gray'
      }`}
    >
      <CheckmarkIcon variant="white" height={12} width={12} />
    </div>
  );
};